<template>
  <div class="mt-3">
    <SectionDivider dividerText="MANDATORY"></SectionDivider>
    <ol class="mandatory-list">
      <FrameItem
        v-for="(i, index) in mandatoryList"
        :key="index"
        :frame="i.frame"
        :title="i.title"
        :scalable="i.scalable"
        :frameKey="i.key"
        :uploadImages="uploadImage"
        @uploadPhoto="uploadPhoto"
        @deleteImg="deleteImg"
        @rotateImg="rotateImg"
        :isMobile="isMobile"
        :isSpecialDeviceBrowser="isSpecialDeviceBrowser"
        @initImgUpload="initImgUpload"
      />
    </ol>
    <PhotoUploadOptions
      :checkReadonly="checkReadonly"
      :imageDeleting="imageDeleting"
      :isAccessingCamera="isAccessingCamera"
      @initImgUploadCamera="initImgUploadCamera"
      ref="mandatoryPhotoOptions"
    />
  </div>
</template>
<script>
import { mandatoryList } from '@/config/constants';
export default {
  name: "MandatorySection",
  data() {
    return {
      mandatoryList,
      isValidImage: true,
    };
  },
  model: {
    prop: "uploadImage",
    event: "change",
  },
  props: {
    uploadImage: {
      type: Array,
      default: () => [],
    },
    isMobile: Boolean,
    isSpecialDeviceBrowser: Boolean,
    isAccessingCamera: Boolean,
    imageDeleting: Boolean,
    checkReadonly: Boolean,
  },
  methods: {
    uploadPhoto: async function (file, key, title) {
      await this.handleValidateImage(file);
      if (this.isValidImage) {
        const imgId = this.$uuid.v4();
        const newUploadImage = [
          ...this.uploadImage,
          {
            id: imgId,
            filename: file.name,
            url: null,
            name: file.name,
            key,
            title,
            onUpload: true,
            progress: 0,
            isAutoplay: false,
          },
        ];
        await this.$emit("change", newUploadImage);
        await this.$emit("handleImgUpload", file, imgId);
      }
    },
    async handleValidateImage(file) {
      await this.$emit("imageValidate", file, this.validateImageCallback);
    },
    validateImageCallback(result) {
      this.isValidImage = result;
    },
    deleteImg(filename, key) {
      this.$emit("deleteImg", filename, key);
    },
    rotateImg(item) {
      this.$emit("rotate", item);
    },
    initImgUpload() {
      this.$refs.mandatoryPhotoOptions.initImgUpload('mandatory');
    },
    initImgUploadCamera() {
      this.$emit("initImgUploadCamera");
    },
  },
  components: {
    SectionDivider: () => import("../common/SectionDivider.vue"),
    FrameItem: () => import("./FrameItem.vue"),
    PhotoUploadOptions: () => import("../photo-upload/PhotoUploadOptions.vue"),
  },
};
</script>
<style lang="scss">
.mandatory-list {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  padding-inline-start: 0;
}
@media screen and(min-width: 900px) {
  .mandatory-list {
    grid-template-columns: repeat(3, 1fr);
    padding: 0 28px 20px;
  }
}
@media screen and(min-width: 992px) {
  .mandatory-list {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and(min-width: 1200px) {
  .mandatory-list {
    grid-template-columns: repeat(5, 169px);
    gap: 20px;
    column-gap: 29px;
  }
}
</style>